<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="title">Default Rates</div>
		</div>
		<div class="container" style="padding-top: 15px;">
			<div class="row">
				<div class="col-sm-2"></div>
				<div class="col-sm-4">
					<div class="form-group ui-float-label">
						<input type="number" class="form-control"
							:class="{ 'is-invalid': fcontrol.minimum_balance?.hasError && fcontrol.minimum_balance.touched }"
							v-model="fdata.minimum_balance" @blur="fcontrol.minimum_balance.touched = true"
							placeholder=" " />
						<div class="invalid-feedback" v-if="fcontrol.minimum_balance?.hasError">
							<span v-if="fcontrol.minimum_balance.errors.required">
								You must enter miminum balance
							</span>
						</div>
						<label>Minimum Balance</label>
					</div>
					<div class="ecg-price-box">
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.cardiology_price?.hasError && fcontrol.cardiology_price.touched }"
								v-model="fdata.cardiology_price" @blur="fcontrol.cardiology_price.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.cardiology_price?.hasError">
								<span v-if="fcontrol.cardiology_price.errors.required">You must enter ECG test price</span>
							</div>
							<label>ECG Test Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.cardiology_doctor_price?.hasError && fcontrol.cardiology_doctor_price.touched }"
								v-model="fdata.cardiology_doctor_price" @blur="fcontrol.cardiology_doctor_price.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.cardiology_doctor_price?.hasError">
								<span v-if="fcontrol.cardiology_doctor_price.errors.required">You must enter cardiology doctor
									price</span>
								<span v-if="fcontrol.cardiology_doctor_price.errors.notgt">Price can not be grater than
									{{ fcontrol.cardiology_doctor_price.errors.notgt.check }}</span>
							</div>
							<label>ECG Doctor Price</label>
						</div>

						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.tmt_price?.hasError && fcontrol.tmt_price.touched }"
								v-model="fdata.tmt_price" @blur="fcontrol.tmt_price.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.tmt_price?.hasError">
								<span v-if="fcontrol.tmt_price.errors.required">You must enter TMT test
									price</span>
							</div>
							<label>TMT Test Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.tmt_doctor_price?.hasError && fcontrol.tmt_doctor_price.touched }"
								v-model="fdata.tmt_doctor_price" @blur="fcontrol.tmt_doctor_price.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.tmt_doctor_price?.hasError">
								<span v-if="fcontrol.tmt_doctor_price.errors.required">You must enter
									TMT doctor price</span>
								<span v-if="fcontrol.tmt_doctor_price.errors.notgt">Price can not be
									grater than {{ fcontrol.tmt_doctor_price.errors.notgt.check }}</span>
							</div>
							<label>TMT Doctor Price</label>
						</div>
					</div>
					<div class="form-group ">
						<span class="p-fluid p-float-label">
							<AutoComplete forceSelection :multiple="true" v-model="selectedCardiologists"
								:suggestions="filteredCardiologists" @complete="searchCardiologist($event)"
								field="name" />
							<label>Selected Cardiologists</label>
						</span>
					</div>

					<div class="pft-price-box">
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.pulmonology_price?.hasError && fcontrol.pulmonology_price.touched }"
								v-model="fdata.pulmonology_price" @blur="fcontrol.pulmonology_price.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.pulmonology_price?.hasError">
								<span v-if="fcontrol.pulmonology_price.errors.required">You must enter PFT test
									price</span>
							</div>
							<label>PFT Test Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.pulmonology_doctor_price?.hasError && fcontrol.pulmonology_doctor_price.touched }"
								v-model="fdata.pulmonology_doctor_price"
								@blur="fcontrol.pulmonology_doctor_price.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.pulmonology_doctor_price?.hasError">
								<span v-if="fcontrol.pulmonology_doctor_price.errors.required">You must enter
									pulmonology doctor price</span>
								<span v-if="fcontrol.pulmonology_doctor_price.errors.notgt">Price can not be
									grater than {{ fcontrol.pulmonology_doctor_price.errors.notgt.check }}</span>
							</div>
							<label>PFT Doctor Price</label>
						</div>
					</div>
					<div style="position: relative; z-index: 99999999 !important;">
						<div class="form-group ">
							<span class="p-fluid p-float-label">
								<AutoComplete forceSelection :multiple="true" v-model="selectedPulmonologists"
									:suggestions="filteredPulmonologists" @complete="searchPulmonologist($event)"
									field="name" />
								<label>Selected Pulmonologists</label>
							</span>
						</div>
					</div>



					<div class="eeg-price-box">
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg2_price?.hasError && fcontrol.eeg2_price.touched }"
								v-model="fdata.eeg2_price" @blur="fcontrol.eeg2_price.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg2_price?.hasError">
								<span v-if="fcontrol.eeg2_price.errors.required">You must enter EEG 2 test
									price</span>
							</div>
							<label>EEG 2 Test Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg3_price?.hasError && fcontrol.eeg3_price.touched }"
								v-model="fdata.eeg3_price" @blur="fcontrol.eeg3_price.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg3_price?.hasError">
								<span v-if="fcontrol.eeg3_price.errors.required">You must enter EEG 3 test
									price</span>
							</div>
							<label>EEG 3 Test Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg4_price?.hasError && fcontrol.eeg4_price.touched }"
								v-model="fdata.eeg4_price" @blur="fcontrol.eeg4_price.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg4_price?.hasError">
								<span v-if="fcontrol.eeg4_price.errors.required">You must enter EEG 4 test
									price</span>
							</div>
							<label>EEG 4 Test Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg5_price?.hasError && fcontrol.eeg5_price.touched }"
								v-model="fdata.eeg5_price" @blur="fcontrol.eeg5_price.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg5_price?.hasError">
								<span v-if="fcontrol.eeg5_price.errors.required">You must enter EEG 5 test
									price</span>
							</div>
							<label>EEG 5 Test Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg6_price?.hasError && fcontrol.eeg6_price.touched }"
								v-model="fdata.eeg6_price" @blur="fcontrol.eeg6_price.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg6_price?.hasError">
								<span v-if="fcontrol.eeg6_price.errors.required">You must enter EEG 6 test
									price</span>
							</div>
							<label>EEG 6 Test Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg7_price?.hasError && fcontrol.eeg7_price.touched }"
								v-model="fdata.eeg7_price" @blur="fcontrol.eeg7_price.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg7_price?.hasError">
								<span v-if="fcontrol.eeg7_price.errors.required">You must enter EEG 7 test
									price</span>
							</div>
							<label>EEG 7 Test Price</label>
						</div>

						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg2_doctor_price?.hasError && fcontrol.eeg2_doctor_price.touched }"
								v-model="fdata.eeg2_doctor_price"
								@blur="fcontrol.eeg2_doctor_price.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg2_doctor_price?.hasError">
								<span v-if="fcontrol.eeg2_doctor_price.errors.required">
									You must enter EEG 2 doctor price
								</span>
								<span v-if="fcontrol.eeg2_doctor_price.errors.notgt">
									Price can not be grater than {{ fcontrol.eeg2_doctor_price.errors.notgt.check }}
								</span>
							</div>
							<label>EEG 2 Doctor Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg3_doctor_price?.hasError && fcontrol.eeg3_doctor_price.touched }"
								v-model="fdata.eeg3_doctor_price"
								@blur="fcontrol.eeg3_doctor_price.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg3_doctor_price?.hasError">
								<span v-if="fcontrol.eeg3_doctor_price.errors.required">
									You must enter EEG 3 doctor price
								</span>
								<span v-if="fcontrol.eeg3_doctor_price.errors.notgt">
									Price can not be grater than {{ fcontrol.eeg3_doctor_price.errors.notgt.check }}
								</span>
							</div>
							<label>EEG 3 Doctor Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg4_doctor_price?.hasError && fcontrol.eeg4_doctor_price.touched }"
								v-model="fdata.eeg4_doctor_price"
								@blur="fcontrol.eeg4_doctor_price.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg4_doctor_price?.hasError">
								<span v-if="fcontrol.eeg4_doctor_price.errors.required">
									You must enter EEG 4 doctor price
								</span>
								<span v-if="fcontrol.eeg4_doctor_price.errors.notgt">
									Price can not be grater than {{ fcontrol.eeg4_doctor_price.errors.notgt.check }}
								</span>
							</div>
							<label>EEG 4 Doctor Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg5_doctor_price?.hasError && fcontrol.eeg5_doctor_price.touched }"
								v-model="fdata.eeg5_doctor_price"
								@blur="fcontrol.eeg5_doctor_price.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg5_doctor_price?.hasError">
								<span v-if="fcontrol.eeg5_doctor_price.errors.required">
									You must enter EEG 5 doctor price
								</span>
								<span v-if="fcontrol.eeg5_doctor_price.errors.notgt">
									Price can not be grater than {{ fcontrol.eeg5_doctor_price.errors.notgt.check }}
								</span>
							</div>
							<label>EEG 5 Doctor Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg6_doctor_price?.hasError && fcontrol.eeg6_doctor_price.touched }"
								v-model="fdata.eeg6_doctor_price"
								@blur="fcontrol.eeg6_doctor_price.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg6_doctor_price?.hasError">
								<span v-if="fcontrol.eeg6_doctor_price.errors.required">
									You must enter EEG 6 doctor price
								</span>
								<span v-if="fcontrol.eeg6_doctor_price.errors.notgt">
									Price can not be grater than {{ fcontrol.eeg6_doctor_price.errors.notgt.check }}
								</span>
							</div>
							<label>EEG 6 Doctor Price</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.eeg7_doctor_price?.hasError && fcontrol.eeg7_doctor_price.touched }"
								v-model="fdata.eeg7_doctor_price"
								@blur="fcontrol.eeg7_doctor_price.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.eeg7_doctor_price?.hasError">
								<span v-if="fcontrol.eeg7_doctor_price.errors.required">
									You must enter EEG 7 doctor price
								</span>
								<span v-if="fcontrol.eeg7_doctor_price.errors.notgt">
									Price can not be grater than {{ fcontrol.eeg7_doctor_price.errors.notgt.check }}
								</span>
							</div>
							<label>EEG 7 Doctor Price</label>
						</div>
					</div>

					<div style="position: relative; z-index: 99999999 !important;">
						<div class="form-group ">
							<span class="p-fluid p-float-label">
								<AutoComplete forceSelection :multiple="true" v-model="selectedNeurologists"
									:suggestions="filteredNeurologists" @complete="searchNeurologist($event)"
									field="name" />
								<label>Selected Neurologists</label>
							</span>
						</div>
					</div>
					

					<div class="center-price-box">
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.price_single_view?.hasError && fcontrol.price_single_view.touched }"
								v-model="fdata.price_single_view" @blur="fcontrol.price_single_view.touched = true"
								placeholder=" " @keyup="doubleRate('single_view')"/>
							<div class="invalid-feedback" v-if="fcontrol.price_single_view?.hasError">
								<span v-if="fcontrol.price_single_view.errors.required">You must enter price single view</span>
							</div>
							<label>Price Single View</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.price_double_view?.hasError && fcontrol.price_double_view.touched }"
								v-model="fdata.price_double_view" @blur="fcontrol.price_double_view.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.price_double_view?.hasError">
								<span v-if="fcontrol.price_double_view.errors.required">You must enter price double view</span>
							</div>
							<label>Price Double View</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.price_multi_view?.hasError && fcontrol.price_multi_view.touched }"
								v-model="fdata.price_multi_view" @blur="fcontrol.price_multi_view.touched = true" placeholder=" " @keyup="doubleRate('multi_view')" />
							<div class="invalid-feedback" v-if="fcontrol.price_multi_view?.hasError">
								<span v-if="fcontrol.price_multi_view.errors.required">You must enter price multi view</span>
							</div>
							<label>Price Multi View</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.price_both_view?.hasError && fcontrol.price_both_view.touched }"
								v-model="fdata.price_both_view" @blur="fcontrol.price_both_view.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.price_both_view?.hasError">
								<span v-if="fcontrol.price_both_view.errors.required">You must enter price both view</span>
							</div>
							<label>Price Both View</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.price_other_view?.hasError && fcontrol.price_other_view.touched }"
								v-model="fdata.price_other_view" @blur="fcontrol.price_other_view.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.price_other_view?.hasError">
								<span v-if="fcontrol.price_other_view.errors.required">You must enter price other view</span>
							</div>
							<label>Price Other View</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.price_opg?.hasError && fcontrol.price_opg.touched }"
								v-model="fdata.price_opg" @blur="fcontrol.price_opg.touched = true" placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.price_opg?.hasError">
								<span v-if="fcontrol.price_opg.errors.required">You must enter price OPG</span>
							</div>
							<label>Price OPG</label>
						</div>
					</div>
				</div>
				<div class="col-sm-4">
					<div class="doctor-price-box">
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.doctor_price_single_view?.hasError && fcontrol.doctor_price_single_view.touched }"
								v-model="fdata.doctor_price_single_view" @blur="fcontrol.doctor_price_single_view.touched = true"
								placeholder=" " @keyup="doubleRate('doctor_single_view')" />
							<div class="invalid-feedback" v-if="fcontrol.doctor_price_single_view?.hasError">
								<span v-if="fcontrol.doctor_price_single_view.errors.required">You must enter doctor price single
									view</span>
								<span v-if="fcontrol.doctor_price_single_view.errors.notgt">Price can not be grater than
									{{ fcontrol.doctor_price_single_view.errors.notgt.check }}</span>
							</div>
							<label>Doctor Price Single View</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.doctor_price_double_view?.hasError && fcontrol.doctor_price_double_view.touched }"
								v-model="fdata.doctor_price_double_view" @blur="fcontrol.doctor_price_double_view.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.doctor_price_double_view?.hasError">
								<span v-if="fcontrol.doctor_price_double_view.errors.required">You must enter doctor price double
									view</span>
								<span v-if="fcontrol.doctor_price_double_view.errors.notgt">Price can not be grater than
									{{ fcontrol.doctor_price_double_view.errors.notgt.check }}</span>
							</div>
							<label>Doctor Price Double View</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.doctor_price_multi_view?.hasError && fcontrol.doctor_price_multi_view.touched }"
								v-model="fdata.doctor_price_multi_view" @blur="fcontrol.doctor_price_multi_view.touched = true"
								placeholder=" " @keyup="doubleRate('doctor_multi_view')" />
							<div class="invalid-feedback" v-if="fcontrol.doctor_price_multi_view?.hasError">
								<span v-if="fcontrol.doctor_price_multi_view.errors.required">You must enter doctor price multi
									view</span>
								<span v-if="fcontrol.doctor_price_multi_view.errors.notgt">Price can not be grater than
									{{ fcontrol.doctor_price_multi_view.errors.notgt.check }}</span>
							</div>
							<label>Doctor Price Multi View</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.doctor_price_both_view?.hasError && fcontrol.doctor_price_both_view.touched }"
								v-model="fdata.doctor_price_both_view" @blur="fcontrol.doctor_price_both_view.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.doctor_price_both_view?.hasError">
								<span v-if="fcontrol.doctor_price_both_view.errors.required">You must enter doctor price both
									view</span>
								<span v-if="fcontrol.doctor_price_both_view.errors.notgt">Price can not be grater than
									{{ fcontrol.doctor_price_both_view.errors.notgt.check }}</span>
							</div>
							<label>Doctor Price Both View</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.doctor_price_other_view?.hasError && fcontrol.doctor_price_other_view.touched }"
								v-model="fdata.doctor_price_other_view" @blur="fcontrol.doctor_price_other_view.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.doctor_price_other_view?.hasError">
								<span v-if="fcontrol.doctor_price_other_view.errors.required">You must enter doctor price other
									view</span>
								<span v-if="fcontrol.doctor_price_other_view.errors.notgt">Price can not be grater than
									{{ fcontrol.doctor_price_other_view.errors.notgt.check }}</span>
							</div>
							<label>Doctor Price Other View</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.doctor_price_opg?.hasError && fcontrol.doctor_price_opg.touched }"
								v-model="fdata.doctor_price_opg" @blur="fcontrol.doctor_price_opg.touched = true"
								placeholder=" " />
							<div class="invalid-feedback" v-if="fcontrol.doctor_price_opg?.hasError">
								<span v-if="fcontrol.doctor_price_opg.errors.required">You must enter doctor price OPG</span>
								<span v-if="fcontrol.doctor_price_opg.errors.notgt">Price can not be grater than
									{{ fcontrol.doctor_price_opg.errors.notgt.check }}</span>
							</div>
							<label>Doctor Price OPG</label>
						</div>
					</div>

					<div class="agent-price-box">
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_single_view?.hasError && fcontrol.min_single_view.touched }"
								v-model="fdata.min_single_view" @blur="fcontrol.min_single_view.touched = true"
								placeholder=" " @keyup="doubleRate('min_single_view')" />

							<label>Single View Minimum Rate</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_double_view?.hasError && fcontrol.min_double_view.touched }"
								v-model="fdata.min_double_view" @blur="fcontrol.min_double_view.touched = true"
								placeholder=" " />

							<label>Double View Minimum Rate</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_multi_view?.hasError && fcontrol.min_multi_view.touched }"
								v-model="fdata.min_multi_view" @blur="fcontrol.min_multi_view.touched = true"
								placeholder=" " @keyup="doubleRate('min_multi_view')" />

							<label>Multi View Minimum Rate</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_both_view?.hasError && fcontrol.min_both_view.touched }"
								v-model="fdata.min_both_view" @blur="fcontrol.min_both_view.touched = true"
								placeholder=" " />

							<label>Both View Minimum Rate</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_other_view?.hasError && fcontrol.min_other_view.touched }"
								v-model="fdata.min_other_view" @blur="fcontrol.min_other_view.touched = true"
								placeholder=" " />

							<label>Other View Minimum Rate</label>
						</div>

						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_opg?.hasError && fcontrol.min_opg.touched }"
								v-model="fdata.min_opg" @blur="fcontrol.min_opg.touched = true"
								placeholder=" " />

							<label>OPG Minimum Rate</label>
						</div>

						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_cardiology?.hasError && fcontrol.min_cardiology.touched }"
								v-model="fdata.min_cardiology" @blur="fcontrol.min_cardiology.touched = true"
								placeholder=" " />

							<label>ECG Minimum Rate</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_tmt?.hasError && fcontrol.min_tmt.touched }"
								v-model="fdata.min_tmt"
								@blur="fcontrol.min_tmt.touched = true" placeholder=" " />

							<label>TMT Minimum Rate</label>
						</div>

						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_pulmonology?.hasError && fcontrol.min_pulmonology.touched }"
								v-model="fdata.min_pulmonology"
								@blur="fcontrol.min_pulmonology.touched = true" placeholder=" " />

							<label>Pulmonology Minimum Rate</label>
						</div>

						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_eeg2?.hasError && fcontrol.min_eeg2.touched }"
								v-model="fdata.min_eeg2"
								@blur="fcontrol.min_eeg2.touched = true" placeholder=" " />

							<label>EEG 2 Minimum Rate</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_eeg3?.hasError && fcontrol.min_eeg3.touched }"
								v-model="fdata.min_eeg3"
								@blur="fcontrol.min_eeg3.touched = true" placeholder=" " />

							<label>EEG 3 Minimum Rate</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_eeg4?.hasError && fcontrol.min_eeg4.touched }"
								v-model="fdata.min_eeg4"
								@blur="fcontrol.min_eeg4.touched = true" placeholder=" " />

							<label>EEG 4 Minimum Rate</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_eeg5?.hasError && fcontrol.min_eeg5.touched }"
								v-model="fdata.min_eeg5"
								@blur="fcontrol.min_eeg5.touched = true" placeholder=" " />

							<label>EEG 5 Minimum Rate</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_eeg6?.hasError && fcontrol.min_eeg6.touched }"
								v-model="fdata.min_eeg6"
								@blur="fcontrol.min_eeg6.touched = true" placeholder=" " />

							<label>EEG 6 Minimum Rate</label>
						</div>
						<div class="form-group ui-float-label">
							<input type="number" class="form-control"
								:class="{ 'is-invalid': fcontrol.min_eeg7?.hasError && fcontrol.min_eeg7.touched }"
								v-model="fdata.min_eeg7"
								@blur="fcontrol.min_eeg7.touched = true" placeholder=" " />

							<label>EEG 7 Minimum Rate</label>
						</div>
						
					</div>
				</div>
				<div class="col-sm-2"></div>
			</div>
			<div class="row" style="padding-bottom: 20px;">
				<div class="col-sm-2"></div>
				<div class="col-sm-4">
					<button class="btn btn-primary btn-lg" @click="save">Save</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Toast from 'primevue/toast';
export default {
	components: {
		Toast,
	},
	data() {
		return {
			saving: false,

			cardiologists: [],
			filteredCardiologists: null,
			selectedCardiologists: [],

			pulmonologists: [],
			filteredPulmonologists: null,
			selectedPulmonologists: [],

			neurologists: [],
			filteredNeurologists: null,
			selectedNeurologists: [],


			fdata: {
				minimum_balance: '',
				price_single_view: '',
				price_double_view: '',
				price_multi_view: '',
				price_both_view: '',
				price_other_view: '',
				price_opg: '',
				doctor_price_single_view: '',
				doctor_price_double_view: '',
				doctor_price_multi_view: '',
				doctor_price_both_view: '',
				doctor_price_other_view: '',
				doctor_price_opg: '',
				// doctor_ids: '',
				cardiology_price: '',
				cardiology_doctor_price: '',
				tmt_price: '',
				tmt_doctor_price: '',
				cardiologist_ids: '',
				pulmonology_price: '',
				pulmonology_doctor_price: '',
				pulmonologist_ids: '',
				eeg2_price: '',
				eeg3_price: '',
				eeg4_price: '',
				eeg5_price: '',
				eeg6_price: '',
				eeg7_price: '',
				// ncv_price: '',
				eeg2_doctor_price: '',
				eeg3_doctor_price: '',
				eeg4_doctor_price: '',
				eeg5_doctor_price: '',
				eeg6_doctor_price: '',
				eeg7_doctor_price: '',
				// ncv_doctor_price: '',
				neurologist_ids: '',
				min_single_view: '',
				min_double_view: '',
				min_multi_view: '',
				min_both_view: '',
				min_other_view: '',
				min_opg: '',
				min_cardiology: '',
				min_tmt: '',
				min_pulmonology: '',
				min_eeg2: '',
				min_eeg3: '',
				min_eeg4: '',
				min_eeg5: '',
				min_eeg6: '',
				min_eeg7: '',
				// min_ncv: '',
			},
			fvalidator: {
				minimum_balance: '',
				price_single_view: 'required',
				price_double_view: 'required',
				price_multi_view: 'required',
				price_both_view: 'required',
				price_other_view: 'required',
				price_opg: 'required',
				doctor_price_single_view: 'required|notgt:price_single_view',
				doctor_price_double_view: 'required|notgt:price_double_view',
				doctor_price_multi_view: 'required|notgt:price_multi_view',
				doctor_price_both_view: 'required|notgt:price_both_view',
				doctor_price_other_view: 'required|notgt:price_other_view',
				doctor_price_opg: 'required|notgt:price_opg',
				// doctor_ids: '',
				cardiology_price: 'required',
				cardiology_doctor_price: 'required|notgt:cardiology_price',
				tmt_price: 'required',
				tmt_doctor_price: 'required|notgt:tmt_price',
				cardiologist_ids: '',
				pulmonology_price: 'required',
				pulmonology_doctor_price: 'required|notgt:pulmonology_price',
				pulmonologist_ids: '',
				eeg2_price: 'required',
				eeg3_price: 'required',
				eeg4_price: 'required',
				eeg5_price: 'required',
				eeg6_price: 'required',
				eeg7_price: 'required',
				// ncv_price: 'required',
				eeg2_doctor_price: 'required|notgt:eeg2_price',
				eeg3_doctor_price: 'required|notgt:eeg3_price',
				eeg4_doctor_price: 'required|notgt:eeg4_price',
				eeg5_doctor_price: 'required|notgt:eeg5_price',
				eeg6_doctor_price: 'required|notgt:eeg6_price',
				eeg7_doctor_price: 'required|notgt:eeg7_price',
				// ncv_doctor_price: 'required|notgt:ncv_price',
				neurologist_ids: '',
				min_single_view: 'required|notgt:price_single_view',
				min_double_view: 'required|notgt:price_double_view',
				min_multi_view: 'required|notgt:price_multi_view',
				min_both_view: 'required|notgt:price_both_view',
				min_other_view: 'required|notgt:price_other_view',
				min_opg: 'required|notgt:price_opg',
				min_cardiology: 'required|notgt:cardiology_price',
				min_tmt: 'required|notgt:tmt_price',
				min_pulmonology: 'required|notgt:pulmonology_price',
				min_eeg2: 'required|notgt:eeg2_price',
				min_eeg3: 'required|notgt:eeg3_price',
				min_eeg4: 'required|notgt:eeg4_price',
				min_eeg5: 'required|notgt:eeg5_price',
				min_eeg6: 'required|notgt:eeg6_price',
				min_eeg7: 'required|notgt:eeg7_price',
				// min_ncv: 'required|notgt:ncv_price',
			}
		}
	},
	async mounted() {
		let res;

		res = await this.post("admin/load-cardiologists", {
			first: 0,
			rows: 1000000,
			sortField: "id",
			sortOrder: -1,
			filters: {
				is_active: { value: 'y', matchMode: '=' }
			}
		})
		this.cardiologists = res.rows;

		res = await this.post("admin/load-pulmonologists", {
			first: 0,
			rows: 1000000,
			sortField: "id",
			sortOrder: -1,
			filters: {
				is_active: { value: 'y', matchMode: '=' }
			}
		})
		this.pulmonologists = res.rows;

		res = await this.post("admin/load-neurologists", {
			first: 0,
			rows: 1000000,
			sortField: "id",
			sortOrder: -1,
			filters: {
				is_active: { value: 'y', matchMode: '=' }
			}
		})
		this.neurologists = res.rows;

		let row = await this.get("admin/load-defualt-rates");
		this.fpopulate(row);

		let cardiologistIds = row.cardiologist_ids ? row.cardiologist_ids.split(",") : [];
		this.selectedCardiologists = this.cardiologists.filter((d) => {
			return cardiologistIds.includes(String(d.id));
		});

		let pulmonologistIds = row.pulmonologist_ids ? row.pulmonologist_ids.split(",") : [];
		this.selectedPulmonologists = this.pulmonologists.filter((d) => {
			return pulmonologistIds.includes(String(d.id));
		});

		let neurologistIds = row.neurologist_ids ? row.neurologist_ids.split(",") : [];
		this.selectedNeurologists = this.neurologists.filter((d) => {
			return neurologistIds.includes(String(d.id));
		});

	},
	methods: {
		async save() {
			if(this.fHasError()){
				console.log("Has Error", this.fcontrol);
			}
			if (this.fHasError() || this.saving) return;
			this.saving = true;
			let data = JSON.parse(JSON.stringify(this.fdata));
			
			data.cardiologist_ids = this.selectedCardiologists.map(d => d.id).join(",");
			data.pulmonologist_ids = this.selectedPulmonologists.map(d => d.id).join(",");
			data.neurologist_ids = this.selectedNeurologists.map(d => d.id).join(",");

			await this.post("admin/save-defualt-rates", {
				default_rates: JSON.stringify(data),
			});
			this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Default rates saved successfully.', life: 3000 });

			this.saving = false;
		},
		doubleRate(type){
			if(type == 'single_view'){
				this.fdata.price_both_view = Number(this.fdata.price_single_view) * 2;
			}else if(type == 'multi_view'){
				this.fdata.price_other_view = Number(this.fdata.price_multi_view) * 2;
			}else if(type == 'min_single_view'){
				this.fdata.min_both_view = Number(this.fdata.min_single_view) * 2;
			}else if(type == 'min_multi_view'){
				this.fdata.min_other_view = Number(this.fdata.min_multi_view) * 2;
			}else if(type == 'doctor_single_view'){
				this.fdata.doctor_price_both_view = Number(this.fdata.doctor_price_single_view) * 2;
			}else if(type == 'doctor_multi_view'){
				this.fdata.doctor_price_other_view = Number(this.fdata.doctor_price_multi_view) * 2;
			}
		},
		searchCardiologist(event) {
			if (!event.query.trim().length) {
				this.filteredCardiologists = [...this.cardiologists];
			} else {
				this.filteredCardiologists = this.cardiologists.filter((d) => {
					return d.name.toLowerCase().indexOf(event.query.toLowerCase()) != -1;
				});
			}
		},
		searchPulmonologist(event) {
			if (!event.query.trim().length) {
				this.filteredPulmonologists = [...this.pulmonologists];
			} else {
				this.filteredPulmonologists = this.pulmonologists.filter((d) => {
					return d.name.toLowerCase().indexOf(event.query.toLowerCase()) != -1;
				});
			}
		},
		searchNeurologist(event) {
			if (!event.query.trim().length) {
				this.filteredNeurologists = [...this.neurologists];
			} else {
				this.filteredNeurologists = this.neurologists.filter((d) => {
					return d.name.toLowerCase().indexOf(event.query.toLowerCase()) != -1;
				});
			}
		},
	},
}
</script>
<style scoped>.center-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #a4c4e3;
	border-radius: 10px;
}

.doctor-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #d9dfe6;
	border-radius: 10px;
}

.agent-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #eec9e9;
	border-radius: 10px;
}



.ecg-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #eeebc9;
	border-radius: 10px;
}
.pft-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #d1ffba;
	border-radius: 10px;
}
.eeg-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #c9eee7;
	border-radius: 10px;
}

</style>